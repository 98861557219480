import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// Components
import { BannerAlt } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import GravityForm from 'components/GravityForm'

import 'styles/templates/ServicesTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        title: banner_title
        image: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      description: content
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const ServicesTemplate = ({
  data: {
    page: {
      path,
      acf: { title, image },
      description,
      yoastMeta,
    },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={image.localFile.childImageSharp.fixed.src} article />
      <div className="services-template pb-5">
        <BannerAlt>
          <BackgroundImage
            className="banner-background-image"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row pt-5">
            <div className="col-lg-8 pt-5">
              <h1 className="mt-5">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
            <div className="col-lg-4" />
          </div>
        </BannerAlt>

        {description && (
          <div
            className="page-container container mt-5 font-family-secondary"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        <div className="page-container container mt-5">
          {/* <GravityForm id={2} /> */}
          <iframe title="Contactformulier" style={{ width: '100%', height: '100%', minHeight: '1000px' }} src="https://meeting.teamleader.eu/embed/form/de-kerstmarktspecialist/contactformulier" frameBorder="0" />
        </div>
      </div>
    </Layout>
  )
}

export default ServicesTemplate
